import React from "react"
import {isChrome,isFirefox,isIeEdge} from "@tightrope/lpscripts/browserdetect"
import Styles from "./css/de_nonsearch.module.scss"

class fflp extends React.Component {
  constructor(props){
    super(props);
    this.state = ({data: {}});
  }
  componentDidMount(){
    if(this.props.data){
      this.state = {
        data: this.props.data
      }
    }
  }
  render() {
    return ( 
      <div id = {Styles.fflpde} >
      <div class= {Styles.disclosure}>
        Diese kostenlose Erweiterung aktualisiert die Sucheinstellungen dieses Browsers. Wenn Sie fortfahren, erkennen Sie die <a href = "/terms-of-service/" target = "_blank" className={Styles.bold}>Nutzungsbedingungen</a> und die <a href = "/privacy-policy/" target = "_blank" className={Styles.bold}>Datenschutzrichtlinie</a>  an und erhalten möglicherweise zusätzliche optionale Angebote.</div></div>
    )
  }
}
export default fflp
